<template>
  <div class="container mt-5">
    <div class="en-tc" v-if="$i18n.locale === 'en'">
      <h1>1 PERSONAL INFORMATION GATHERER ON THE WEBSITE</h1>
      <h2>1.1 Introduction</h2>
      <p>
        By providing any personal data on this website, you confirm that you
        fully understand the terms of processing of this personal data to the
        extent stated in this document.
      </p>
      <h2>
        1.2 What personal data is gathered when visiting analyticalplatform.com?
      </h2>
      <p>
        We fundamentally do not require registration to access
        https://analyticalplatform.com/. However, if you take part in any
        activities or services provided on https://analyticalplatform.com/, we
        will gather personal data we need to provide these services. It can be
        the following:
      </p>
      <ul>
        <li>
          Address and identification details (e.g. name, surname, address);
        </li>
        <li>
          Contact details (e.g. telephone number, email address, postal address
          or mobile number);
        </li>
        <li>
          Descriptive details (e.g. data about your activities or interests in
          our services);
        </li>
        <li>
          We do not collect sensitive personal details via
          https://analyticalplatform.com/.
        </li>
      </ul>
      <h2>1.3 Technical Information</h2>
      <p>
        When visiting this website, we can collect technical information such as
        IP address (internet protocol), details about visited pages [website
        URL], other pages you visit on the website and which browser you used to
        show https://analyticalplatform.com/.
      </p>
      <p>
        This website collects standard internet protocol and technical details
        which are measured, used for website efficiency improvement, help with
        our server problems diagnosis, manage this website, detect where the
        website traffic comes from and identify our users. We also collect other
        information via https://analyticalplatform.com/, for example, activities
        and preferences for using the website, also known as demographic or
        profile details. In connection with this, we use “cookies” to collect
        information. More information can be found in our cookie policy.
      </p>
      <h2>
        1.4 When do we collect personal data via the
        https://analyticalplatform.com/ website?
      </h2>
      <p>We collect your personal data in the following cases:</p>
      <ul>
        <li>If you arrange a meeting with us</li>
        <li>If you ask us to contact you</li>
        <li>
          We collect a minimum amount of information which enables us to deal
          with your query.
        </li>
        <li>
          We state where providing the information is voluntary or mandatory.
        </li>
        <li>
          We usually require more information only in the case it enables us to
          provide a more suitable answer to your query.
        </li>
      </ul>
      <h2>1.5 On what basis do we handle your personal data?</h2>
      <p>
        We must have a legal basis to process your personal data. In most cases,
        the legal basis will be one of the following:
      </p>
      <ul>
        <li>
          Conclusion of a contract and fulfilment of our contractual obligations
          towards you
        </li>
        <li>Fulfilment of legal obligations which we are subject to</li>
        <li>
          Consent to the processing of personal data in case of sending e.g.
          newsletter and other business messages
        </li>
        <li>
          Promoting our legitimate interests, for instance, to make our record
          up-to-date and accurate.
        </li>
      </ul>
      <h2>1.6 How do we use your personal data?</h2>
      <p>
        We use only personal data you provide to us in order to deal with your
        query and manage https://analyticalplatform.com/ and services which are
        offered via https://analyticalplatform.com/. When applying for materials
        from your side or making a request to be contacted by someone from our
        company, we will be able to to gather more information which can be the
        subject of your interest in our products.
      </p>
      <h1>2 COOKIES</h1>
      <h2>2.1 Introduction</h2>
      <p>
        Information about users of this website is gathered automatically and
        also by a voluntary decision to provide this information – for example
        by filling in a form on https://analyticalplatform.com/. This
        information is processed confidentially and the CCFR SOFTWARE, s.r.o.
        company uses it under the conditions set out in this Privacy Policy.
        However, we can use “cookies” to store and sometimes to track
        information about you.
      </p>
      <h2>2.2 Cookies</h2>
      <p>
        Cookies are small text files which are downloaded to your computer or
        mobile device when visiting websites or applications. Your web browser
        (such as Internet Explorer, Mozilla Firefox or Google Chrome) sends
        these cookies back on our website or application every subsequent visit
        in order to recognise you and remember your personal data or user
        preferences. Cookies do not harm your system. You can update your
        browser in such a way so it refuses any cookies or sends a notification
        when sending cookies.
      </p>
      <p>
        Cookies are used to manage the system and to analyse the traffic of our
        website in order to enhance user experience and improve its quality.
        This provides us with information about the number of visitors in
        different parts of the website.
      </p>
      <p>
        CCFR SOFTWARE, s.r.o. company does not share this data with third
        parties. Our cookies do not save data such as your name or address, but
        help us see your behaviour on the website, which enhances your user
        experience. However, if you want to limit, block or delete cookies from
        https://analyticalplatform.com/ or any other website, you can use your
        browser. Every browser is different, therefore, find your way to the
        “Help” menu of your browser (or a mobile phone manual) where you will
        find how to change cookies settings.
      </p>
      <h3>2.3 How to handle and manage cookies</h3>
      <p>
        There are multiple ways how to handle and manage cookies which are
        described in detail below. However, do not forget that all settings you
        change do not affect only https://analyticalplatform.com/ cookies. These
        changes will apply to all websites you visit (unless you decide to block
        cookies only on specific websites).
      </p>
      <h2>2.4 Cookies management in the browser</h2>
      <p>Most of the modern browsers allow you:</p>
      <ul>
        <li>Find out which cookies you have and delete them individually</li>
        <li>Block third-party cookies</li>
        <li>Block cookies of specific websites</li>
        <li>Block sending all cookies</li>
        <li>Delete all cookies after closing the browser</li>
      </ul>
      <p>
        You should know that after deleting cookies, any preferences will be
        lost. This includes websites where you have opted out of cookies because
        it requires cookie opt-out settings. If you block cookies, many websites
        will not function properly and some functions on this website will not
        work at all.
      </p>
      <h2>2.5 Cookies management for analysis</h2>
      <p>
        It is also possible to stop activity tracking by using the anonymous
        browsing mode on websites where cookies are used for analysis.
        https://analyticalplatform.com/ uses Google Analytics, and it is up to
        you whether you decide to opt-out of your cookies on the Google
        Analytics opt-out page. Please note that you will be redirected to the
        Google Analytics server so that a “no thanks” cookie can be generated
        for you; this will stop any further cookies being set by these third
        parties.
      </p>
      <h2>2.6 More information about cookies and how to manage them</h2>
      <p>
        If you want to learn more about cookies and how to manage them, visit
        aboutcookies.org. Please note that we cannot be responsible for the
        content of external websites.
      </p>
      <h1>3 SENDING COMMERCIAL MESSAGES</h1>
      <p>
        We can process your personal data (name, surname, phone number, email
        address) in order to send you commercial communications (newsletter,
        information about our services). We send commercial messages under Act
        No. 480/2004 Coll., on certain information society services, only with
        your consent. This consent can be revoked at any time.
      </p>
      <p>
        If you are our client, we can send you commercial messages without prior
        consent. In this case, you can refuse to receive further commercial
        messages at any time.
      </p>
      <h1>4 PERSONAL DATA COLLECTED BY OUR OUR COMPANY OUTSIDE THIS WEBSITE</h1>
      <p>
        We can get in touch with you outside this website and process your
        personal data. You can be interested in the service, customer, supplier
        or a job applicant. In these cases, we process your personal data in
        order to:
      </p>
      <ul>
        <li>
          Conclude a contract with you and fulfil our obligations agreed in the
          contract with you
        </li>
        <li>Make sure our records are accurate and up-to-date</li>
        <li>Ensure the protection of our company’s property</li>
        <li>
          Fulfil legal obligations we are subject to. To do this, we must have a
          legal basis for processing your personal data. In most cases, the
          legal basis will be one of the following:
        </li>
        <li>
          Conclusion of a contract and fulfilment of our contractual obligations
          towards you
        </li>
        <li>Fulfilment of legal obligations which we are subject to</li>
        <li>Consent to the processing of personal data</li>
        <li>
          Promoting our legitimate interests, for instance, to make our record
          up-to-date and accurate
        </li>
      </ul>
      <h1>5 RIGHTS RELATED TO THE PROCESSING OF PERSONAL DATA</h1>
      <h2>5.1 Your rights</h2>
      <ul>
        <li>You have the right to:</li>
        <li>Access to personal data</li>
        <li>Update or edit your data</li>
        <li>Pass your personal data to a different administrator</li>
        <li>Require your data to be deleted from our systems</li>
        <li>
          Alternatively, raise a complaint against the processing by sending an
          email to info@ccfr.cz. All these complaints will be handled under
          applicable laws.
        </li>
      </ul>
      <p>
        If you decide not to accept promotional or marketing emails from us, you
        can let us know by sending an email to info@ccfr.cz, stating your email
        address which you want to be deleted from our mailing list. However,
        your decision not to receive promotional and marketing emails does not
        restrict us from communicating – via email or other means – in matters
        of your current relationship with us. CCFR SOFTWARE, s.r.o. company will
        realise relevant rights in accordance with the law and with regard to
        the available technology and the cost of undertaking appropriate steps,
        including technical measures.
      </p>
      <h2>
        5.2 The right to revoke consent to the processing of personal data
      </h2>
      <p>
        If you have given your consent to a certain type of processing,
        including processing for the purposes of business communications, you
        can revoke it at any time. If you revoke your consent, this does not
        affect the lawfulness of the processing of personal data prior to this
        withdrawal.
      </p>
      <h1>6 HOW LONG DO WE KEEP YOUR PERSONAL DATA?</h1>
      <p>
        Your data is kept only for the time needed to meet your request or other
        legitimate processing purposes and will be deleted in accordance with
        our privacy policy.
      </p>
      <h1>7 SHARING PERSONAL DATA</h1>
      <p>
        We do not provide your personal data to third parties (except for
        service suppliers processing personal data for on behalf of us) unless
        we have a legal basis to do so. We can conclude a contract with other
        companies or individuals in order to deal with your query or otherwise
        run this website or our business activities. Such companies can be
        provided with access to your personal data on our behalf in connection
        with these purposes. We do not provide information to third parties for
        their marketing purposes, and we do not send emails on behalf of third
        parties.
      </p>
      <p>
        As a result of selling, fusing, consolidating, changing of control,
        asset transfer, reorganisation or liquidation of our company
        (“reorganisation event”), we can transfer, sell or forward your personal
        data to third parties within this reorganisational event.
      </p>
      <p>
        The situations in which we can provide your personal data to a third
        party are:
      </p>
      <ul>
        <li>
          to the extent permitted by applicable law, in order to protect and
          defend our rights, property and legitimate interests; and
        </li>
        <li>
          if required by applicable law (for example, public authorities,
          including the administration authorities).
        </li>
      </ul>
      <h1>8 TRANSFERRING DATA OUTSIDE THE CZECH REPUBLIC</h1>
      <p>
        Your personal data can be transferred, saved and processed in a
        different country from the Czech Republic. If we do so, we transfer the
        personal data under applicable data protection regulations. If the
        transfer takes place to a country outside the EEA, we will use standard
        contractual clauses approved by the European Commission.
      </p>
      <h1>9 SECURING THE DATA</h1>
      <p>
        We implemented generally accepted technological and operational-security
        standards in order to protect the personal data from loss, abuse, edits
        and deletion. All the employees and representatives of our organisation
        are required to keep the personal data confidentially. Only authorised
        personnel have access to this data.
      </p>
      <p>
        We store your personal information in accordance with our privacy
        policy.
      </p>
      <h1>10 CONTACT US</h1>
      <p>
        We are determined to cooperate with you in such a way to reach a fair
        solution in any potential complaints or doubts related to personal data
        protection. But if you believe that we could not help you with your
        complaint or doubt, you have the right to complain to the Office for
        Personal Data Protection headquarters: Pplk. Sochora 27, 170 00 Praha 7,
        IČO: 70837627, tel.: +420 234 665 111, website: https://www.uoou.cz.
      </p>
      <h1>11 UPDATE OF THIS PERSONAL DATA PROTECTION STATEMENT</h1>
      <p>
        This personal data protection statement can be continuously edited or
        updated. You will find out the last update of the statement since it
        always includes the date of the update. Changes and additions to this
        personal data protection statement are valid from the day of their
        publishing. We kindly ask you to read regularly through this personal
        data protection statement in order to find out whether any changes have
        been made in the way your personal data is used
      </p>
      <p>Last update on 22, Apr 2021</p>
    </div>
    <div class="cs-tc" v-else>
      <h1>1 OSOBNÍ ÚDAJE SHROMAŽĎOVANÉ NA WEBOVÝCH STRÁNKÁCH</h1>
      <h2>1.1 Úvodní informace</h2>
      <p>
        Poskytnutím jakýchkoli osobních údajů na těchto webových stránkách
        potvrzujete, že jste plně porozuměli podmínkám zpracování těchto
        osobních údajů v rozsahu uvedeném v tomto oznámení.
      </p>
      <h2>
        1.2 Jaké osobní údaje jsou shromažďovány při návštěvě
        https://analyticalplatform.com?
      </h2>
      <p>
        Pro přístup k https://analyticalplatform.com/ zásadně nepožadujeme
        registraci – pokud se však účastníte některé z aktivit nebo služeb
        nabízených na adrese https://analyticalplatform.com/, budeme
        shromažďovat osobní údaje, které k poskytování těchto služeb
        potřebujeme. Může se jednat například o:
      </p>
      <ul>
        <li>Adresní a identifikační údaje (např. jméno, příjmení, adresu);</li>
        <li>
          Kontaktní údaje (např. telefonní číslo, emailovou adresu, poštovní
          adresu nebo číslo mobilního telefonu);
        </li>
        <li>
          Popisné údaje (např. údaje o Vaší činnosti nebo zájmu o některou ze
          služeb).;
        </li>
        <li>
          Neshromažďujeme od Vás citlivé osobní údaje prostřednictvím
          https://analyticalplatform.com/.
        </li>
      </ul>
      <h2>1.3 Technické informace</h2>
      <p>
        Při návštěvě tohoto webu můžeme shromažďovat technické informace, jako
        například adresu IP (internetový protokol), podrobnosti o stránkách,
        které navštívíte na [URL webových stránek], jiné stránky, které
        navštívíte na webu a který prohlížeč jste použili k
        zobrazeníhttps://analyticalplatform.com/.
      </p>
      <h2>
        1.4 Kdy shromažďujeme osobní údaje prostřednictvím adresy
        https://analyticalplatform.com/?
      </h2>
      <p>Osobní údaje od Vás shromažďujeme v následujících případech:</p>
      <ul>
        <li>Domluvíte-li si s námi schůzku.</li>
        <li>Požádáte, abychom Vás kontaktovali.</li>
        <li>
          Shromažďujeme minimální množství informací, které nám umožní se Vaší
          žádostí zabývat.
        </li>
        <li>Uvedeme, kde je poskytování informací dobrovolné nebo povinné.</li>
        <li>
          Obvykle budeme požadovat další informace pouze v případě, pokud nám
          umožní poskytnout vhodnější odpověď na Vaši žádost
        </li>
      </ul>
      <h2>1.5 Z jakého důvodu zpracováváme vaše osobní data?</h2>
      <p>
        Musíme mít právní základ pro zpracování Vašich osobních údajů. Ve
        většině případů bude právní základ jedním z následujících:
      </p>
      <ul>
        <li>Uzavření smlouvy a splnění našich smluvních závazků vůči Vám</li>
        <li>Plnění zákonných povinností, kterým podléháme</li>
        <li>
          Souhlas se zpracováním osobních údajů v případě zasílání např.
          newsletteru a jiných obchodních sdělení
        </li>
        <li>
          Prosazení našich oprávněných zájmů, například aby naše záznamy byly
          aktuální a přesné.
        </li>
      </ul>
      <h2>1.6 Jak Vaše osobní údaje používáme?</h2>
      <p>
        Používáme pouze osobní údaje, které nám poskytnete, abychom se mohli
        zabývat Vaší žádostí, a spravovat https://analyticalplatform.com/ a
        služby, které Vám prostřednictvím https://analyticalplatform.com/
        nabízíme. Při podání žádosti o materiály z Vaší strany nebo vznesení
        požadavku, aby Vás někdo z naší společnosti kontaktoval, budeme mít
        možnost získat další informace, které mohou být předmětem Vašeho zájmu o
        naše produkty.
      </p>
      <h1>2 COOKIES</h1>
      <h2>2.1 Úvod</h2>
      <p>
        Informace o uživateli této webové stránky shromažďujeme automaticky a
        také pokud se rozhodnete nám tyto informace sami poskytnout – například
        vyplněním formuláře na adrese https://analyticalplatform.com/. Tyto
        informace jsou zpracovávány důvěrně a společnost CCFR SOFTWARE, s.r.o.
        je používá pouze v souladu s podmínkami uvedenými v tomto oznámení o
        ochraně osobních údajů. Můžeme používat „cookies“ k uchování a někdy
        také ke sledování informací o Vás.
      </p>
      <h2>2.2 Soubory cookie</h2>
      <p>
        Cookies jsou malé textové soubory, které jsou staženy do Vašeho počítače
        nebo mobilního zařízení při návštěvě webových stránek nebo aplikací. Váš
        webový prohlížeč (například Internet Explorer, Mozilla Firefox nebo
        Google Chrome) pošle tyto cookies zpět na webovou stránku nebo aplikaci
        při každé následující návštěvě, aby Vás bylo možné rozpoznat a pamatovat
        si např. osobní údaje nebo uživatelské preference. Soubory cookie
        nepoškodí Váš systém. Můžete obnovit svůj prohlížeč tak, aby bylo možné
        odmítnout jakýkoli soubor cookie nebo abyste na odeslání souboru cookie
        byli upozorněni. Soubory cookie používáme pro správu systému a analýzu
        návštěvnosti našich webových stránek za účelem zlepšení uživatelské
        zkušenosti a zlepšování služeb. To nám poskytuje informace o počtu
        návštěvníků různých částí našeho webu.
      </p>
      <p>
        Společnost CCFR SOFTWARE, s.r.o. tyto údaje nesdílí s třetími stranami.
        Naše soubory cookie neukládají údaje, jako je Vaše jméno nebo adresa,
        ale spíše nám umožňují vidět Vaše chování na webu, což přispěje ke
        zlepšení Vaší uživatelské zkušenosti. Pokud však chcete omezit,
        zablokovat nebo odstranit soubory cookie z adresy
        https://analyticalplatform.com/ nebo jiné webové stránky, můžete k tomu
        použít Váš prohlížeč. Každý prohlížeč je jiný, a proto se podívejte do
        nabídky „Nápověda“ konkrétního prohlížeče (nebo příručky mobilního
        telefonu), kde se dozvíte, jak změnit předvolby souborů cookie.
      </p>
      <h3>2.3 Jak ovládat soubory cookie</h3>
      <p>
        Existují různé způsoby, jak můžete ovládat a spravovat soubory cookie,
        které jsou podrobněji popsány níže. Nezapomeňte však, že všechna
        nastavení, která změníte, neovlivní pouze soubory cookie
        https://analyticalplatform.com/. Tyto změny se budou vztahovat na
        všechny webové stránky, které navštívíte (pokud se nerozhodnete blokovat
        soubory cookie pouze z konkrétních webových stránek).
      </p>
      <h2>2.4 Správá cookies v prohlížeči</h2>
      <p>Většina moderních prohlížečů Vám umožní:</p>
      <ul>
        <li>Zjistit, jaké cookies máte a individuálně je odstranit</li>
        <li>Blokovat cookies třetích stran</li>
        <li>Blokovat cookies konkrétních webových stránek</li>
        <li>Blokovat odesílání všech souborů cookie</li>
        <li>Odstranit všechny soubory cookie po uzavření prohlížeče</li>
      </ul>
      <p>
        Měli byste si být vědomi, že pokud odstraníte soubory cookie, jakékoliv
        předvolby budou ztraceny. To zahrnuje také weby, kde jste se od souborů
        cookie odhlásili, protože to vyžaduje nastavení odhlášení cookies. Pokud
        soubory cookie zablokujete, mnoho webových stránek nebude fungovat
        správně a některé funkce na těchto webových stránkách nebudou fungovat
        vůbec.
      </p>
      <h2>2.5 Správa cookies pro analýzu</h2>
      <p>
        Je možné rovněž zrušit zaznamenávání aktivit v režimu anonymizovaného
        procházení v rámci webových stránek, které je prováděno soubory cookie
        pro analýzu. https://analyticalplatform.com/ používá službu Google
        Analytics a je na Vás, zdali se rozhodnete pro odhlášení svých souborů
        cookie na stránce pro odhlášení služby Google Analytics. Vezměte prosím
        na vědomí, že budete přesměrováni na webový server společnosti Google
        Analytics, aby zde mohl být vygenerován cookie „ne díky“, který zastaví
        další soubory cookie nastavené těmito třetími stranami.
      </p>
      <h2>2.6 Další informace o cookies a o tom, jak je spravovat</h2>
      <p>
        Pokud se chcete dozvědět více o souborech cookie a jak je spravovat,
        navštivte stránku aboutcookies.org. Vezměte prosím na vědomí, že
        nemůžeme být zodpovědní za obsah externích webových stránek.
      </p>
      <h1>3 ZASÍLÁNÍ OBCHODNÍCH SDĚLENÍ</h1>
      <p>
        Můžeme zpracovávat Vaše osobní údaje (jméno, příjmení, telefonní číslo,
        emailová adresa) za účelem zasílání obchodních sdělení (newsletter,
        informace o našich službách). Obchodní sdělení zasíláme v souladu se
        zákonem č. 480/2004 Sb., o některých službách informační společnosti,
        pouze s Vaším předchozím souhlasem. Tento souhlas můžete kdykoliv
        odvolat.
      </p>
      <p>
        Pokud jste naším klientem, můžeme Vám obchodní sdělení zasílat bez
        předchozího souhlasu. V takovém případě máte kdykoliv možnost další
        zasílání obchodních sdělení kdykoliv odmítnout.
      </p>
      <h1>4 OSOBNÍ ÚDAJE SHROMAŽĎOVANÉ SPOLEČNOSTÍ MIMO WEBOVÉ STRÁNKY</h1>
      <p>
        Můžeme se s Vámi dostat do kontaktu také mimo webové stránky a
        zpracovávat Vaše osobní údaje. Můžete být zájemce o službu, zákazník,
        náš dodavatel, nebo uchazeč o zaměstnání. V uvedených případech Vaše
        osobní údaje zpracováváme, abychom:
      </p>
      <ul>
        <li>
          Mohli uzavřít s Vámi smlouvu a plnit naše závazky ujednané ve smlouvě
          s Vámi
        </li>
        <li>Zajistili, že naše záznamy jsou přesné a aktuální</li>
        <li>Zajistili ochranu majetku naší společnosti</li>
        <li>
          Plnili zákonné povinnosti, kterým podléháme. K tomu musíme mít právní
          základ pro zpracování Vašich osobních údajů. Ve většině případů bude
          právní základ jedním z následujících:
        </li>
        <li>Uzavření smlouvy a splnění našich smluvních závazků vůči Vám</li>
        <li>Plnění zákonných povinností, kterým podléháme</li>
        <li>Souhlas se zpracováním osobních údajů</li>
        <li>
          Prosazení našich oprávněných zájmů, například aby naše záznamy byly
          aktuální a přesné.
        </li>
      </ul>
      <h1>5 PRÁVA SOUVISEJÍCÍ SE ZPRACOVÁNÍM OSOBNÍCH ÚDAJŮ</h1>
      <h2>5.1 Vaše práva</h2>
      <ul>
        <li>Máte právo požadovat:</li>
        <li>Přístup k osobním údajům</li>
        <li>Aktualizovat nebo opravovat své údaje</li>
        <li>Předání Vašich osobních údajů jinému správci</li>
        <li>Odhlásit odběr dalších informací od společnosti</li>
        <li>
          Požadovat, aby Vaše osobní údaje byly z našich systémů odstraněny
        </li>
        <li>
          Případně vznést námitku vůči zpracování a to zasláním e-mailu na
          software@ccfr.com Všechny tyto požadavky budeme řešit v souladu s
          platnými právními předpisy.
        </li>
      </ul>
      <p>
        Pokud byste se v budoucnu rozhodli od nás nepřijímat propagační nebo
        marketingové e-maily, můžete nám to sdělit zasláním e-mailu na
        software@ccfr.com s uvedením e-mailové adresy, kterou si přejete
        odstranit z našeho mailing listu. Nicméně, Vaše volba nepřijímat
        propagační a marketingové e-maily nám nebrání v komunikaci – e-mailem
        nebo jinak – ve věcech Vašeho stávajícího vztahu s námi. Společnost CCFR
        SOFTWARE, s.r.o. bude v souladu s právními předpisy příslušná práva
        realizovat také s ohledem na dostupnou technologii a náklady na
        provedení přiměřených kroků, včetně technických opatření.
      </p>
      <h2>5.2 Právo odvolat souhlas se zpracováním osobních údajů</h2>
      <p>
        Pokud jste dali souhlas s určitým typem zpracování, včetně zpracování
        pro účely zasílání obchodních sdělení, můžete jej kdykoliv odvolat.
        Pokud souhlas odvoláte, není tím dotčena zákonnost zpracování osobních
        údajů před tímto odvoláním.
      </p>
      <h1>6 JAK DLOUHO UCHOVÁVÁME VAŠE OSOBNÍ ÚDAJE?</h1>
      <p>
        Vaše údaje uchováváme pouze po dobu potřebnou pro splnění Vašeho
        požadavku nebo jiných legitimních účelů zpracování a odstraníme je v
        souladu s našimi zásadami uchovávání údajů
      </p>
      <h1>7 SDÍLENÍ OSOBNÍCH ÚDAJŮ</h1>
      <p>
        Vaše osobní údaje neposkytujeme třetím stranám (kromě poskytovatelů
        služeb zpracovávajících osobní údaje pro nás a našim jménem), pokud pro
        to nemáme právní základ.
      </p>
      <p>
        Můžeme uzavřít smlouvu s jinými společnostmi nebo jednotlivci, abychom
        se zabývali Vaší žádostí nebo jinak provozovali tuto webovou stránku
        nebo naše obchodní aktivity. Takovým společnostem můžeme poskytnout
        přístup k Vašim osobním údajům v našem zastoupení v souvislosti s těmito
        účely. Neposkytujeme informace třetím stranám pro jejich vlastní
        marketingové účely a nezasíláme e-maily jménem třetí strany.
      </p>
      <p>
        V důsledku prodeje, fúze, konsolidace, změny v kontrole, převodu aktiv,
        reorganizace nebo likvidace naší společnosti („reorganizační událost“)
        můžeme převést, prodat nebo postoupit Vaše osobní údaje třetím osobám v
        rámci této reorganizační události. Situace, v nichž můžeme Vaše osobní
        údaje poskytnout třetí straně, jsou:
      </p>
      <ul>
        <li>
          pokud to platné právní předpisy umožňují, za účelem ochrany a hájení
          našich práv, majetku a oprávněných zájmů a
        </li>
        <li>
          pokud to stanoví platné právní předpisy (jde například o orgány
          veřejné moci, včetně úřadů veřejné správy).
        </li>
      </ul>
      <h1>8 PŘENOS ÚDAJŮ MIMO ČESKOU REPUBLIKU</h1>
      <p>
        Vaše osobní údaje mohou být převedeny, uloženy a zpracovány v jiné zemi,
        než v České republice. Pokud tak učiníme, předáváme osobní údaje v
        souladu s platnými předpisy o ochraně osobních údajů. Pokud se přenos
        uskuteční do země mimo EHP, použijeme standardní smluvní doložky
        schválené Evropskou komisí.
      </p>
      <h1>9 ZABEZPEČENÍ ÚDAJŮ</h1>
      <p>
        Zavedli jsme obecně uznávané technologické a provozně-bezpečnostní
        standardy, abychom ochránili osobní údaje před ztrátou, zneužitím,
        úpravami nebo zničením. Od všech zaměstnanců a představitelů naší
        organizace požadujeme, aby osobní údaje chovali v tajnosti. K těmto
        údajům mají přístup pouze autorizovaní pracovníci.
      </p>
      <p>
        Vaše osobní údaje uchováme v souladu s našimi zásadami uchovávání údajů.
      </p>
      <h1>10 KONTAKTUJTE NÁS</h1>
      <p>
        Jsme odhodláni spolupracovat s Vámi tak, abychom dosáhli spravedlivého
        řešení jakékoliv případné stížnosti nebo obav ohledně ochrany osobních
        údajů. Pokud se ovšem domníváte, že jsme Vám s Vaší stížností nebo
        obavami nebyli schopni pomoci, máte právo podat stížnost k Úřadu na
        ochranu osobních údajů, sídlo: Pplk. Sochora 27, 170 00 Praha 7, IČO:
        70837627, tel.: +420 234 665 111, web: https://www.uoou.cz.
      </p>
      <h1>11 AKTUALIZACE TOHOTO PROHLÁŠENÍ O OCHRANĚ OSOBNÍCH ÚDAJŮ</h1>
      <p>
        Toto oznámení o ochraně osobních údajů může být postupně upravováno nebo
        aktualizováno. Budete mít možnost zjistit, kdy jsme toto oznámení
        naposledy aktualizovali, protože bude vždy obsahovat datum revize. Změny
        a dodatky k tomuto oznámení o ochraně osobních údajů jsou platné ode dne
        jejich zveřejnění. Přečtěte si prosím čas od času toto oznámení o
        ochraně osobních údajů, abyste zjistili, zda byly provedeny nějaké změny
        ve způsobu, jakým Vaše osobní údaje používáme.
      </p>
      <p>Naposledy změněno 22.04.2021</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
h1,
h2,
h3 {
  margin-top: 2rem;
}
</style>